import * as React from 'react';
import { LinkProps } from '@wix/thunderbolt-components';
import { IImageXProps } from '../ImageX.types';
import { TestIds } from '../constants';
import Link from '../../Link/viewer/Link';
import style from './style/ImageX.scss';

const getPictureSource = (
  sourceSets: IImageXProps['imageInfo']['sourceSets'],
) =>
  sourceSets.map((srcSet, i) => (
    <source key={i} media={srcSet.mediaQuery} srcSet={srcSet.src} />
  ));

const getImageLayer = (
  id: string,
  imageInfo: IImageXProps['imageInfo'],
  defaultSrc?: string,
) => {
  return (
    <wix-image
      id={`img-${id}`} // must for wix-image custom element
      data-image-info={JSON.stringify(imageInfo)}
    >
      <picture>
        {imageInfo.sourceSets && getPictureSource(imageInfo.sourceSets)}
        <img src={defaultSrc} alt={imageInfo.imageData.alt} />
      </picture>
    </wix-image>
  );
};

const getScrollEffectLayer = (
  containerId: string,
  pageId: string,
  hasScrollEffect: boolean,
  imageLayer: React.ReactNode,
) => {
  if (hasScrollEffect) {
    return (
      <wix-bg-media
        data-page-id={pageId}
        data-container-id={containerId}
        data-use-css-vars="true"
      >
        {imageLayer}
      </wix-bg-media>
    );
  }

  return imageLayer;
};

const getLinkLayer = (
  link: LinkProps | undefined,
  showLink: boolean,
  scrollEffectLayer: React.ReactNode,
) => {
  if (link && showLink) {
    return <Link {...link}>{scrollEffectLayer}</Link>;
  }

  return scrollEffectLayer;
};

const ImageX: React.FC<IImageXProps> = (props: IImageXProps) => {
  const {
    id,
    link,
    skin,
    pageId,
    showLink,
    imageInfo,
    defaultSrc,
    hasScrollEffects,
    scrollEffectStyles,
    onClick,
    onDblClick,
  } = props;
  return (
    <div
      id={id}
      data-testid={TestIds.root}
      className={style[skin]}
      onClick={onClick}
      onDoubleClick={onDblClick}
    >
      <style data-testid={TestIds.scrollEffectStyle}>
        {scrollEffectStyles}
      </style>
      {getLinkLayer(
        link,
        showLink,
        getScrollEffectLayer(
          id,
          pageId,
          hasScrollEffects,
          getImageLayer(id, imageInfo, defaultSrc),
        ),
      )}
    </div>
  );
};

export default ImageX;
